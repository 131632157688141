import { RecipeTracking } from './recipes';
import { SearchTracking } from './search';
import { ProductsTracking } from './products';
import { LinkTracking } from './links';
import { FooterTracking } from './footer';
import { SocialMediaTracking } from './socialMedia';
import { NewsletterTracking } from './newsletter';
import { BasicTracking } from './basic';
import { PromotionTracking } from './promotion';

export const TrackingService = {
  ...BasicTracking,
  ...SocialMediaTracking,
  ...FooterTracking,
  ...LinkTracking,
  ...ProductsTracking,
  ...SearchTracking,
  ...RecipeTracking,
  ...NewsletterTracking,
  ...PromotionTracking,
};
